@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/table/lib/css/table.css';
@import '~@blueprintjs/select/lib/css/blueprint-select.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';

/* TODO: use variables from Blueprint */
.App {
  display: flex;
  height: 100vh;
}

.content {
  flex: 1;
  background: #e1e8ed;
}

.content-centered {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.bp4-spinner.centered {
  margin: 0 auto;
}

.content-centered form .bp4-form-group {
  width: 100%;
}

.content-centered form h5 {
  margin-top: 20px;
}

.profile {
  display: flex;
}

.profile .avatar {
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
  height: 40px;
  width: 40px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.profile .avatar img {
  display: inline;
  height: 100%;
  width: auto;
}

.profile .avatar + div {
  line-height: 50px;
}

label.bp4-label .bp4-popover-wrapper.inline-tooltip-wrapper {
  display: inline-block;
  margin: 0;
  margin-right: 5px;
}

.bp4-table-quadrant-left .bp4-table-cell.bp4-table-last-in-row {
  -webkit-box-shadow: inset -3px 0 0 rgba(17, 20, 24, 0.15);
  box-shadow: inset -2px 0 0 rgba(17, 20, 24, 0.15);
}
